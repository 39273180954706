<template>
  <v-main>
    <v-container fluid class="mt-16">
      <v-flex xs12 sm6 offset-sm3 mt-16 >
        <v-card
            class="mx-auto"
            outlined
            elevation="5"
        >
          <v-card-text>
        <form class="signup form" @submit.prevent="forget" ref="forget" >
          <v-layout column>
            <v-flex>
              <v-text-field
                  name="email"
                  label="Почта"
                  id="username"
                  prepend-icon="mdi-account"
                  v-model="username"
                  type="email"
                  required></v-text-field>
            </v-flex>
            <v-flex class="text-xs-center" mt-5>
              <v-btn color="success" type="submit" elevation="0">Востановить</v-btn>
            </v-flex>
          </v-layout>
        </form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import error from "@/constants/error";
import axios from "axios";

export default {
  data () {
    return {
      username: '',
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/")
    }
  },
  methods: {
    forget: function() {
      let formData = new FormData();
      formData.append("email", this.username);
      axios.post(process.env.VUE_APP_API_URL + "/api/v1/account/forget", formData)
          .then(() => {
            this.$store.dispatch("showSnack", {
              text: "Сообщение со ссылкой на востановление пароля отправлено Вам на почту!",
              color: "success"})

          })
          .catch((err)=>{
            error.show_error(err.response.data.data)
          });
      this.username = ''
    }
  }
};
</script>
